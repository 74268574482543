import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import dynamic from "next/dynamic";
import Link from "next/link";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { siteOptions } from "../../data/siteOptions";
import { formatPhone } from "../../services/formatPhone";
import { HeaderStyles } from "./HeaderStyles";
import LargeNav from "./LargeNav/LargeNav";
import NavToggle from "./NavToggle/NavToggle";
import splitHoursMinutes from "@/helpers/splitHoursMinutes";
import { isOfficeHours } from "@/services/isOfficeHours";
import Cookies from "js-cookie";
import checkLeadValuesSource from "@/services/checkLeadValuesSource";
import holidays from "@/services/holidays";

import { useRouter } from "next/router";

const SidebarNav = dynamic(() => import("./SidebarNav/SidebarNav"), {
  ssr: false,
});

const PullUpSchedulerV2 = dynamic(
  () => import("@components/PullUpScheduler/PullUpSchedulerV2"),
  { ssr: false }
);

const Logo = () => (
  <img
    src="/static/images/logo1800a.svg"
    alt="1-800 Accountant Logo"
    width="149px"
    height="33px"
  />
);

/**
 * Header component for all pages.
 * @param {Boolean} hideLinks Hides the menu lins
 * @param {Boolean} isHome Defaults to false, checks if current page is home or not.
 * @param {String} customClass Custom classes for the header.
 * @param {Array} hideException Show particular items.
 * @param {Object} leadValues Used for appointments lead, campaign and channel values stored
 * @param {Object} phone_number Phone number to be shown, will default if not set
 * @param {Boolean} PaidCampaign Sets if appointment comes from a campaign
 * @param {Boolean} EntityCampaign Optional param, checks if lead came from entity campaign.
 * @param {Object} AdditionalQuestions Optional param, use if you want to override scheduler questions
 * @param {Object} localizationStrings Optional param, use if you want to override localization values
 * @param {Boolean} hideSchedulerHeader Optional param, use if you want to hide the header links according to the new scheduler requirements || show only logo and phone
 * @param {Boolean} EForm Optional param, use if the assigned team for the page should be Entity Formation Team
 * @param {Object} setIsSchedulerOpenParent Optional param, use if the parent requires to know the open state of the scheduler
 */
const Header = ({
  hideLinks = false,
  hideException = [],
  leadValues = false,
  phone_number,
  PaidCampaign,
  EntityCampaign,
  phone,
  customClass,
  AdditionalQuestions = false,
  localizationStrings = false,
  AssignOwner = false,
  isHome = false,
  doElite = false,
  isTaxServicesLLC,
  currentSlug = "",
  hideSchedulerHeader = false,
  overrideLead = false,
  EForm = false,
  setIsSchedulerOpenParent = false,
  hideHamburger = false,
}) => {
  const [sidebarNavIsOpen, setSidebarNavIsOpen] = useState(false);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const [conditionalPhone, setConditionalPhone] = useState("");
  const [schedulerHeader, setSchedulerHeader] = useState(false);
  const [hideHeaderLinks, setHideHeaderLinks] = useState(hideLinks);
  const [hideHeaderException, setHideHeaderException] = useState(hideException);

  const router = useRouter();

  dayjs.extend(advancedFormat);
  dayjs.extend(timezone);

  const today = new Date();
  const currentDate = dayjs(today).format("MM/DD/YY");

  //Update phone bsed on received number, start time and end time to know wether to show phone number or not.
  useEffect(() => {
    const updatePhone = async () => {
      const { office_hours, offline_phone_number, online_phone_number } =
        phone_number;
      const startTime = splitHoursMinutes(office_hours.start_time);
      const endTime = splitHoursMinutes(office_hours.end_time);

      //Sets open hours, if not open it will hide the phone number from header
      const isOpen = await isOfficeHours({
        startTime,
        endTime,
        isHome,
        currentSlug,
      });

      if (isOpen) {
        setConditionalPhone(online_phone_number);
        return;
      }
      setConditionalPhone("");
    };

    if (phone_number) {
      updatePhone();
    }
  }, [phone_number]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      return isSchedulerOpen
        ? document.body.classList.add("no-overflow")
        : document.body.classList.remove("no-overflow");
    }

    return () => {
      isMounted = false;
    };
  }, [isSchedulerOpen]);

  useEffect(() => {
    if (isSchedulerOpen) {
      setHideHeaderLinks(true);
      setHideHeaderException(["phone", "logo"]);
    } else {
      setHideHeaderLinks(hideLinks);
      setHideHeaderException(hideException);
    }
  }, [isSchedulerOpen]);

  // used to determine if a parent component requires the state of the open state of the scheduler
  useEffect(() => {
    if (setIsSchedulerOpenParent) {
      setIsSchedulerOpenParent(isSchedulerOpen);
    }
  }, [isSchedulerOpen]);

  // Hides the header links except the phone and logo when the scheduler is open
  useEffect(() => {
    if (hideSchedulerHeader) {
      setHideHeaderLinks(true);
      setHideHeaderException(["phone", "logo"]);
    } else {
      setHideHeaderLinks(hideLinks);
      setHideHeaderException(hideException);
    }
  }, [hideSchedulerHeader, hideLinks]);

  useEffect(() => {
    setTimeout(function () {
      if (leadValues && leadValues != "undefined") {
        if (overrideLead) {
          leadValues = checkLeadValuesSource(leadValues);
        }
        Cookies.set("leadValues", leadValues);
      }
    }, 1000);
  }, [leadValues]);

  //Function used to toggle the Scheduler on click event
  const handleOpenScheduler = () => {
    setIsSchedulerOpen(true);
    setSidebarNavIsOpen(false);
  };

  return (
    <>
      <HeaderStyles
        hideHeaderLinks={hideHeaderLinks}
        hideHeaderException={hideHeaderException}
        className={customClass}
      >
        <div className="container-fluid">
          <div className="header-wrap">
            {!hideHamburger && !hideHeaderLinks && (
              <NavToggle
                setSidebarNavIsOpen={setSidebarNavIsOpen}
                sidebarNavIsOpen={sidebarNavIsOpen}
              />
            )}
            <div className="logo">
              {!hideHeaderLinks || hideHeaderException.includes("logo") ? (
                <Link href="/">
                  <a
                    title="Go to 1800-Accountant homepage"
                    data-cy="header-site-logo"
                  >
                    <Logo />
                  </a>
                </Link>
              ) : (
                <Logo />
              )}
            </div>
            {!hideHeaderLinks && (
              <div className="large-nav-wrap">
                <LargeNav data={siteOptions.header_navigation} />
              </div>
            )}
            <div className="ctas d-flex justify-content-end d-sm-grid">
              {!hideHeaderLinks || hideHeaderException.includes("phone")
                ? conditionalPhone &&
                (holidays.includes(currentDate) && router.pathname != "/" ? (
                  ""
                ) : (
                  <div className="phone-number-container">
                    <img
                      className="header-phone-icon"
                      src="/icons/header-phone.svg"
                      alt="phone icon"
                      width="24px"
                      height="24px"
                    />
                    <a
                      href={`tel:${conditionalPhone}`}
                      className="phone-number"
                    >
                      {formatPhone(conditionalPhone)}
                    </a>

                    <a
                      href={`tel:${conditionalPhone}`}
                      className="btn btn-header btn-call"
                    >
                      <ReactSVG
                        className="icon"
                        src="/icons/phone-outgoing.svg?type=svg"
                      />
                      Call
                    </a>
                  </div>
                ))
                : conditionalPhone && (
                  <div className="phone-number-container">
                    <div className="phone-icon-container">
                      <ReactSVG
                        className="phone-icon"
                        src="/static/images/orange-phone-icon.svg?type=svg"
                        alt="Orange phone icon"
                      />
                    </div>
                    <a
                      href={`tel:${conditionalPhone}`}
                      className="phone-number flow-number"
                    >
                      {formatPhone(conditionalPhone)}
                    </a>

                    <a
                      href={`tel:${conditionalPhone}`}
                      className="btn btn-header btn-call"
                    >
                      <ReactSVG
                        className="icon"
                        src="/icons/phone-outgoing.svg?type=svg"
                      />
                      Call
                    </a>
                  </div>
                )}

              {!hideHeaderLinks || hideHeaderException.includes("login") ? (
                <a href="/sso/#/login" className="btn btn-header btn-signin">
                  <ReactSVG
                    className="icon"
                    src="/icons/lock-closed.svg?type=svg"
                  />
                  Sign In
                </a>
              ) : null}

              {!hideHeaderLinks || hideHeaderException.includes("start") ? (
                <button
                  type="button"
                  className="btn btn-header btn-get-started"
                  data-cy="btn-scheduler-start"
                  onClick={() => {
                    setIsSchedulerOpen(true);
                  }}
                >
                  {localizationStrings
                    ? localizationStrings.headerStartFree
                    : "Start for Free"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </HeaderStyles>
      <SidebarNav
        data={siteOptions.header_navigation}
        sidebarNavIsOpen={sidebarNavIsOpen}
        handleOpenScheduler={handleOpenScheduler}
        closeSideNav={() => setSidebarNavIsOpen(false)}
      />
      {isSchedulerOpen && (
        <PullUpSchedulerV2
          AdditionalQuestions={AdditionalQuestions}
          leadValues={leadValues}
          setIsSchedulerOpen={setIsSchedulerOpen}
          PaidCampaign={PaidCampaign}
          EntityCampaign={EntityCampaign}
          localizationStrings={localizationStrings}
          customClass={customClass}
          AssignOwner={AssignOwner}
          doElite={doElite}
          isTaxServicesLLC={isTaxServicesLLC}
          EForm={EForm}
        />
      )}
    </>
  );
};

Header.propTypes = {
  hideLinks: PropTypes.bool,
  hidePhone: PropTypes.bool,
  phone: PropTypes.string,
  leadValues: function (props, propName, componentName) {
    // make leadValues not required if hideLinks is true
    if (
      (props["hideLinks"] == false || !props["hideLinks"]) &&
      props[propName] == undefined
    ) {
      return new Error(
        `Invalid prop '${propName}'; '${props[propName]}' supplied to '${componentName}'`
      );
    }
  },
};

export default React.memo(Header);
