const checkLeadValuesSource = (leadValues) => {
  const query = JSON.parse(localStorage.getItem('query'));
  const utm_sources = query.utm_source.split(',');
  if (utm_sources.length) {
    const utm_source = utm_sources.pop().trim()
    const allowed = ['web.com', 'openworks', 'bluehost', 'register.com', 'networksolutions'];
    if (leadValues && allowed.includes(utm_source.toLowerCase())) {
      if (utm_source === 'networksolutions') {
        leadValues.lead_source = 'Network Solutions';
      } else {
        leadValues.lead_source = utm_source;
      }
    }
  }
  return leadValues
};

export default checkLeadValuesSource;
