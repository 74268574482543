import React from 'react';
import { NavToggleStyles } from './NavToggleStyles';

const NavToggle = ({ sidebarNavIsOpen, setSidebarNavIsOpen }) => {

  return (
    <NavToggleStyles className={`nav-toggle ${sidebarNavIsOpen ? 'is-active' : ''}`}>
      <button
        type="button"
        className="hamburger-box"
        onClick={() => {
          setSidebarNavIsOpen(!sidebarNavIsOpen);
        }}
      >
        <div className="hamburger-inner"></div>
      </button>
    </NavToggleStyles>
  )
};
export default React.memo(NavToggle);