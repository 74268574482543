import styled from "styled-components";

export const HeaderStyles = styled.div`
  background: white;
  padding: 0.75rem 0;
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  .header-wrap {
    display: flex;
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 2px;
    ${(props) => props.theme.media.sm} {
      display: flex;
      align-items: center;
    }
    .ef-clp {
      grid-template-columns: repeat(2, auto);
    }
  }
  .logo {
    
    a {
      display: block;
    }
    img {
      padding: 5px;
      max-height: 32px;
      margin-top: -4px;
      ${(props) => props.theme.media.sm} {
        margin-top: 0;
        max-height: 100%;
      }
    }
    svg {
      display: block;
      height: 1.5rem;
      width: auto;
      ${(props) => props.theme.media.sm} {
        height: 1rem;
      }
      ${(props) => props.theme.media.md} {
        height: 1.5rem;
      }
    }
  }
  .ctas {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.25rem;
    ${(props) => props.theme.media.sm} {
      grid-gap: 1rem;
      ${(props) =>
        !props.hideLinks ||
        props.hideException.includes("phone") ||
        props.hideException.includes("start") ||
        props.hideException.includes("login")
          ? `grid-template-columns: repeat(3, auto);`
          : null}
      margin-left: auto;
    }
  }
  .btn-header {
    padding: 0.2rem 0.5rem;
    min-width: 0;
    font-size: 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25em;
    height: 1.5rem;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.media.sm} {
      border-radius: 2em;
      padding: 0.33rem 1rem;
      height: auto;
    }
    .icon {
      display: inline-block;
      margin-right: 0.5em;
    }
    svg {
      height: 1.25em;
      display: inline-block;
    }
  }
  .btn-call {
    background: ${(props) => props.theme.secondary};
    border-color: ${(props) => props.theme.secondary};
    color: white;
    ${(props) => props.theme.media.sm} {
      display: none;
    }
    &:hover {
      color: white;
    }
  }
  .btn-signin {
    border-color: ${(props) => props.theme.primary};
    display: none;
    ${(props) => props.theme.media.sm} {
      display: block;
    }
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.primary};
      background: ${(props) => props.theme.primary};
      color: white;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(45 163 141 / 0.5);
    }
  }
  .btn-get-started {
    display: none;
    background: ${(props) => props.theme.secondary};
    border-color: ${(props) => props.theme.secondary};
    color: white;
    text-transform: none;
    ${(props) => props.theme.media.sm} {
      display: block;
    }
    &:hover {
      filter: brightness(1.1);
      color: white;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(241 95 34 / 0.5);
    }
  }
  .phone-number {
    color: #495057;
    display: none;
    white-space: nowrap;
    font-weight: 500;
    text-decoration: underline;
    ${(props) => props.theme.media.sm} {
      display: block;
    }
  }
  .flow-number {
    font-size: 18px;
    text-decoration: none;
  }
  .large-nav-wrap {
    display: none;
    margin-left: auto;
    ${(props) => props.theme.media.xl} {
      display: block;
    }
  }
  .phone-icon-container {
    display: none;
  }
  .phone-number-container {
    display: flex;
    align-items: center;
  }
  ${(props) => props.theme.media.lg} {
    .phone-icon-container {
      display: block;
      margin-right: 8px;
    }
  }

  .header-phone-icon {
    display: none;

    ${(props) => props.theme.media.lg} {
      display: block;
      margin-right: 5px;
    }
  }
`;
