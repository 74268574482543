import styled from 'styled-components';

export const LargeNavStyles = styled.div`
  .top-level {
    display: block;
    position: relative;
    margin: -1rem 0;

    &:hover,
    &:focus-within {
      .top-level-item {
        text-decoration: none;
        border-color: ${props => props.theme.primary};
        color: ${props => props.theme.primary};
      }

      .level-two {
        margin-top: 0;
        opacity: 1;
        pointer-events: all;
      }

      .level-three {
        margin-left: 0.5rem;
      }
    }
  }

  .top-level-item {
    font-size: 0.875rem;
    font-weight: 500;
    display: block;
    margin: 0;
    background: transparent;
    border: none;
    color: ${props => props.theme.gray700};
    padding: 0.25rem 0.75rem;
    border-bottom: 3px solid transparent;

    &:hover {
    }

    &.dropdown {
      display: flex;

      svg {
        height: 1em;
        margin-left: 0.5em;
      }
    }
  }

  ul.large-nav {
    padding: 0;
    margin: 0 -0.5rem;
    list-style: none;
    display: flex;
  }

  .level-two {
    display: block;
    position: absolute;
    background: white;
    z-index: 99;
    padding: 0.5rem 0;
    margin: 0;
    list-style: none;
    min-width: 16em;
    margin-top: 1rem;
    opacity: 0;
    pointer-events: none;
    font-size: 0.875rem;
    box-shadow: 0 15px 35px 0 rgba(42, 51, 83, 0.12), 0 5px 15px rgba(0, 0, 0, 0.06);
    transition: all 0.25s ease-out;

    > li {
      a {
        display: block;
        padding: 0.25rem 1rem;
        white-space: nowrap;
        border-left: 3px solid transparent;
        color: ${props => props.theme.gray700};

        &:hover,
        &:focus {
          color: ${props => props.theme.primary};
          text-decoration: none;
          border-color: ${props => props.theme.primary};
          text-decoration: none;
        }
      }
    }
  }
`;
