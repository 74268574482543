import React from 'react';
import Link from 'next/link';
import { ReactSVG } from 'react-svg'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react';
import excludedEndpoints from './excludedEndpoints.js'
import { LargeNavStyles } from './LargeNavStyles';


const LargeNav = ({ data }) => {
  const router = useRouter();
  //converts static data prop into state;
  const [navItems, setNavItems] = useState(data);

  //used to conditionally render CLP's;
  useEffect(() => {

    //clean up variable;
    let execute = true;

    //checks existing endpoint;
    let path = router.asPath;
    // Handles cases when utms are added to the path
    const currentEndpoint = path.split("?")[0];

    if (execute) {

      //checks if user in an 'excluded endpoint';
      let match = excludedEndpoints.find(eE => eE === currentEndpoint);

      if (match) {

        //filter for only acceptable Desktop menu items;
        setNavItems(navItems.filter((topLevelItem) => topLevelItem.title === 'Services' || topLevelItem.title === 'Blog'));

      }

    }

    return () => {

      //cleans up useEffect if component is prematurely unmounted
      execute = false;

    }

  }, []);

  return (
    <LargeNavStyles>
      <ul className="large-nav">
        {navItems.map(topLevelItem =>
          topLevelItem.header && (
            <li className="top-level" key={topLevelItem.title}>
              {topLevelItem.dropdown ? (
                <>
                  <button className="top-level-item dropdown" type="button">
                    {topLevelItem.title} <ReactSVG src="/icons/dropdown.svg?type=svg" />
                  </button>
                  <ul className="level-two">
                    {topLevelItem.dropdown.map(item => (
                      <React.Fragment key={item.title}>
                        <li>
                          <Link href={item.url}>
                            <a>{item.title}</a>
                          </Link>
                        </li>
                        {item.children?.length ? item.children.map(i => (
                          <li className="level-three" key={`${item.title}-${i.title}`}>
                            <Link href={i.url}>
                              <a>{i.title}</a>
                            </Link>
                          </li>
                        )) : null}
                      </React.Fragment>
                    ))}
                  </ul>
                </>
              ) : (
                <Link href={topLevelItem.url}>
                  <a className="top-level-item">{topLevelItem.title}</a>
                </Link>
              )}
            </li>
          )
        )}
      </ul>
    </LargeNavStyles>
  );
}
export default React.memo(LargeNav);
