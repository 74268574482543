export const siteOptions = {
  header_navigation: [
    {
      title: "Pricing",
      sidebar: true,
      dropdown: [
        {
          title: "Packages and Pricing",
          description: "",
          url: "/pricing",
        },
      ],
    },
    {
      title: "Services",
      sidebar: true,
      header: true,
      dropdown: [
        {
          title: "Start a Business",
          url: "/services/entity-formation",
          children: [
            {
              title: "File an EIN",
              url: "/services/ein-tax-id-filing",
            },
          ],
        },
        {
          title: "Small Business Taxes",
          url: "/services/small-business-taxes",
          children: [
            {
              title: "Tax Advisory",
              description: "",
              url: "/services/business-tax-advisory",
            },
            {
              title: "Audit Defense",
              description: "",
              url: "/services/audit-defense",
            },
            {
              title: "Quarterly Estimated Taxes",
              description: "",
              url: "/services/quarterly-estimated-taxes",
            },
            {
              title: "Income Taxes",
              description: "",
              url: "/services/income-taxes",
            },
          ],
        },
        {
          title: "Bookkeeping",
          description: "",
          url: "/services/bookkeeping",
        },
        {
          title: "Payroll",
          description: "",
          url: "/services/payroll",
        },
      ],
    },
    {
      title: "About",
      sidebar: true,
      header: true,
      dropdown: [
        {
          title: "Company",
          description: "",
          url: "/about",
        },
        {
          title: "Client Spotlights",
          description: "",
          url: "/client-spotlight",
        },
        {
          title: "Careers",
          description: "",
          url: "/careers",
        },
        {
          title: "News",
          description: "",
          url: "/news",
        },
      ],
    },
    {
      title: "Pricing",
      header: true,
      url: "/pricing",
    },
    {
      title: "Partnerships",
      sidebar: true,
      header: true,
      url: "/partnerships",
    },
    {
      title: "Support",
      sidebar: true,
      header: true,
      url: "/support",
    },
    {
      title: "Blog",
      sidebar: true,
      header: true,
      url: "/blog",
    },
  ],
  footer_navigation: [
    {
      title: "Services",
      links: [
        {
          text: "Start a Business",
          url: "/services/entity-formation",
        },
        {
          text: "Small Business Taxes",
          url: "/services/small-business-taxes",
        },
        {
          text: "Bookkeeping",
          url: "/services/bookkeeping",
        },
        {
          text: "Payroll",
          url: "/services/payroll",
        },
        {
          text: "Tax Advisory",
          url: "/services/business-tax-advisory",
        },
        {
          text: "Audit Defense",
          url: "/services/audit-defense",
        },
        {
          text: "Quarterly Estimated Taxes",
          url: "/services/quarterly-estimated-taxes",
        },
        {
          text: "Income Taxes",
          url: "/services/income-taxes",
        },
        {
          text: "File an EIN",
          url: "/services/ein-tax-id-filing",
        },
      ],
    },
    {
      title: "Pricing",
      links: [
        {
          text: "Packages and Pricing",
          url: "/pricing",
        },
      ],
      subsection: {
        title: "Industries",
        links: [
          {
            text: "Trucking",
            url: "/services/industry/trucking",
          },
          {
            text: "Real Estate",
            url: "/services/industry/real-estate",
          },
          {
            text: "E-commerce",
            url: "/services/industry/ecommerce",
          },
          {
            text: "Self-Employed",
            url: "/services/industry/freelancer-self-employed",
          },
          {
            text: "Non-Profit",
            url: "/services/industry/nonprofit",
          },
        ],
      },
      subsection_2: {
        title: "Work With Us",
        links: [
          {
            text: "Careers",
            url: "/careers",
          },
          {
            text: "Become an Affiliate",
            url: "/affiliate ",
          },
        ],
      }
    },
    {
      title: "Customer Support",
      links: [
        {
          text: "Check Tax Return Status",
          url: "/support/return-status"
        },
        {
          text: "Schedule Time With Your Team",
          url: "/support/appointment"
        },
        {
          text: "Billing Support",
          url: "/support/billing"
        },
      ],
      subsection: {
        title: "Resources",
        links: [
          {
            text: "Frequently Asked Questions",
            url: "/faq",
          },
          {
            text: "Blog",
            url: "/blog",
          },
        ],
      },
      subsection_2: {
        title: "Company",
        links: [
          {
            text: "About",
            url: "/about",
          },
          {
            text: "Client Spotlight",
            url: "/client-spotlight",
          },
          {
            text: "Partnerships",
            url: "/partnerships",
          },
          {
            text: "Contact Us",
            url: "/support/contact-us",
          },
        ],
      },
    },
  ],
  sign_in: {
    title: "Sign In",
    url: "/sso/#/login",
  },
};
