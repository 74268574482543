import styled from 'styled-components';

export const NavToggleStyles = styled.div`
  ${props => props.theme.media.xl} {
    display: none;
  }

  .hamburger-box {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    outline: none;
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    position: absolute;
    width: 24px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: ${props => props.theme.gray700};
  }

  .hamburger-inner {
    top: 3px;

    &:before,
    &:after {
      content: '';
      display: block;
    }

    &:before {
      top: 8px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, opacity;
    }

    &:after {
      top: 16px;
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: translate3d(0, 8px, 0) rotate(45deg);

      &:before {
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        opacity: 0;
      }

      &:after {
        transform: translate3d(0, -16px, 0) rotate(-90deg);
      }
    }
  }
`;
