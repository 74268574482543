const excludedEndpoints = [
    '/accounting-services-business',
    '/bookkeeping-services-business',
    '/tax-services-business',
    '/lp/clientbooks',
    '/lp/quarterly-estimated-taxes',
    '/lp/business-tax-deductions',
    '/lp/local-business-taxes',
    '/lp/tax-services-llc',
    '/lp/affiliate-partners',
    '/lp/tax-services-self-employed',
    '/lp/tax-services-nonprofit',
    '/lp/servicios-contables',
    '/lp/accounting-services-trucking',
    '/lp/accounting-services-ecomm',
    '/lp/accounting-services-geo',
    '/lp/accounting-services-real-estate',
    '/lp/accounting-services-crypto',
    '/accounting-services-business-v1',
    '/accounting-services-business-v2',
    '/accounting-services-v2-business',
    '/tax-services-v2-business',
    '/lp/tax-services-btp',
    '/lp/business-tax-advisory',
    '/lp/accounting-services-v2-geo',
    'tax-services-v2-business',
    '/lp/payroll',
    '/lp/v2-payroll',
    '/lp/tax-services-1099'
];

export default excludedEndpoints;