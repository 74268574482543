export const formatPhone = (phone = '18002226868') => {
  let phoneToFormat = phone;
  if (typeof phoneToFormat === 'string' && phoneToFormat.charAt(0) !== '1') {
    phoneToFormat = `1${phone}`;
  }
  const countryCode = phoneToFormat.charAt(0);
  const areaCode = phoneToFormat.substr(1, 3);
  const firstSet = phoneToFormat.substr(4, 3);
  const secondSet = phoneToFormat.substr(7, 4);
  return `${countryCode}-${areaCode}-${firstSet}-${secondSet}`;
};
